export default {
  add: "Add",
  address: "Address",
  back: "Back",
  configuration: "Configuration",
  configure: "Configure",
  continue: "Continue",
  download: "Download",
  edit: "Edit",
  equipmentTag: "Equipment Tag",
  id: "ID",
  notes: "Notes",
  pointList: "Point List",
  quantity: "Quantity",
  results: "Results",
  save: "Save",
  schedule: "Schedule",
  schedules: "Schedules",
  schematics: "Schematics",
  sequence: "Sequence",
  size: "Size",
  systems: "Systems",
  toggleFullscreen: "Toggle Fullscreen",
  type: "Type",
  units: "Units",
  upload: "Upload",
};
